import React from 'react'
import DashboardPage from '../components/DashboardPage'
import Layout from '../components/Layout/Layout'

function Dashboard(props) {
  return (
    <Layout {...props}>
      <DashboardPage {...props} />
    </Layout>
  )
}
export default Dashboard
