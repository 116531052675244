import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Paper } from '@material-ui/core'
import { isLoggedIn } from '../../utils/auth'
import { navigate } from 'gatsby'
import Chart from './Chart'
import Title from './Title'
import LicensesRecent from './LicensesRecent'
import ConsultRevisions from '../RevisionsPage/ConsultRevisions'
import { Auth } from 'aws-amplify'

export default function DashboardPage(props) {
  const classes = useStyles()
  const [rowItems, setRowItems] = useState([])
  const [title, setTitle] = useState('')
  const [ userAttributes, setUserAttributes] = useState('')

  useEffect(() => {
    ;(async () => {
      if (await !isLoggedIn()) {
        navigate('/signin/')
      }
      const { attributes } = await Auth.currentAuthenticatedUser()
      setUserAttributes(attributes)
    })()
  }, [])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ConsultRevisions
          {...props}
          chart={true}
          setCallBack={setRowItems}
          setTitle={setTitle}
        />

        <Paper elevation={1} className={clsx(classes.paperTitle)}>
          <Title>{title}</Title>
          <Paper
            elevation={0}
            className={clsx(classes.paper, classes.fixedHeight)}
          >
            <Chart {...props} rowItems={rowItems} />
          </Paper>
        </Paper>
      </Grid>
      { ( userAttributes['custom:isAdmin'] === 'true' || userAttributes['custom:isAdmin'] === true ) && <Grid item xs={12}>
        <Paper className={classes.paper}>
          <LicensesRecent {...props} />
        </Paper>
      </Grid>}
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(1),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    '& > .recharts-surface': {
      width: 'auto',
    },
  },
  fixedHeight: {
    height: 350,
  },
  paperTitle: {
    padding: theme.spacing(2),
  },
}))
