import React, { useState, useEffect } from 'react'
import 'moment/locale/es-us'
// import moment from 'moment'

// import Moment from 'react-moment'
import { Link } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'

import Title from './Title'
import { handleConnectionApiGet } from '../../utils/ApiConnect'
// import { parseDate } from '../../utils/tools'

export default function LicensesRecent(props) {
  const classes = useStyles()
  const [rows, setRows] = useState([])
  const [LastEvaluatedKey, setLastEvaluatedKey] = useState(null)
  const [loading, setLoading] = useState(false)

  const getLicenses = async (event = null) => {
    if (event) event.preventDefault()
    setLoading(true)
    await handleConnectionApiGet('/licenses', { ...LastEvaluatedKey, Limit: 5 })
      .then((response) => {
        const { items, LastEvaluatedKey } = response.Response
        setLastEvaluatedKey(LastEvaluatedKey)
        setRows(rows.concat(items))
      })
      .catch((error) => {
        console.log(error)
      })
    setLoading(false)
  }

  useEffect(() => {
    const abortController = new AbortController()
    getLicenses()
    return function cleanup() {
      abortController.abort()
    }
  }, [])

  return (
    <React.Fragment>
      <Title>Recientes licencias</Title>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Sitio web</TableCell>
            <TableCell>Tipo de licencia</TableCell>
            <TableCell>Fecha de emisión </TableCell>
            <TableCell>Fecha de vencimiento </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, key) => (
            <TableRow key={key}>
              <TableCell>{row.frEmail}</TableCell>
              <TableCell>{row.web || row.back_url}</TableCell>
              <TableCell>{row.licenseType}</TableCell>
              <TableCell>
                {/* <Moment format="DD-MM-YYYY h:mm:ss a"> */}
                {row.timestampCreated}
                {/* </Moment> */}
              </TableCell>
              <TableCell>{row.timestampActiveUntil}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={classes.seeMore}>
        {LastEvaluatedKey && !loading ? (
          <Link
            color="primary"
            to="/licenses/"
            onClick={() => {
              getLicenses()
            }}
          >
            Ver más licencias
          </Link>
        ) : loading ? (
          'Descargando...'
        ) : (
          ''
        )}
      </div>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}))
