import React, { useState, useEffect } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'
import { withWidth } from '@material-ui/core'
import { parseDateDate } from '../../utils/tools'

function Chart(props) {
  let chartWidth = 800
  const [dataItems, setDataItems] = useState([])
  const { width, rowItems } = props

  if (width === 'xs') {
    chartWidth = 350
  } else if (width === 'sm') {
    chartWidth = 750
  }

  useEffect(() => {
    const abortController = new AbortController()

    let licenses = [],
      result = null
    for (let item of rowItems) {
      result = licenses.filter(
        (ite) => parseDateDate(item.timestampCreatedISO) === ite.name
      )[0]
      let index = licenses.indexOf(result)

      if (result) {
        result.send = item.success ? result.send + 1 : result.send
        result.failed = !item.success ? result.failed + 1 : result.failed
        licenses[index] = result
      } else {
        licenses.push({
          name: parseDateDate(item.timestampCreatedISO),
          send: item.success ? 1 : 0,
          failed: !item.success ? 1 : 0,
        })
      }
    }

    setDataItems(licenses)

    return function cleanup() {
      abortController.abort()
    }
  }, [rowItems])

  return (
    <LineChart
      width={chartWidth}
      height={300}
      data={dataItems}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 0,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line
        dataKey="send"
        type="monotone"
        name="Correos enviados"
        stroke="#60D957"
      />
      <Line
        dataKey="failed"
        type="monotone"
        data={dataItems}
        name="Correos fallidos"
        stroke="red"
      />
    </LineChart>
  )
}

export default withWidth()(Chart)
